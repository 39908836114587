const BASE_URL = process.env.https://abp.villapinedo.nl;

const URLS = {
  BASE: BASE_URL,
  CMSKIT: `${BASE_URL}/api/cms-kit`,
  FILE: `${BASE_URL}/api/file`,
  APP: `${BASE_URL}/api/app`,
  ADULTS: `https://volwassenen.villapinedo.nl`,
  PAGES: {
    OPEN_LETTERS_PARENTS: "/open-brieven-ouders",
    MEDIA_REPORTS: "/mediaberichten",
    MEDIA_REPORTS_DETAIL: "/in-de-media",
    VACANCIES: "/vacatures",
    SPONSOR_BUDDY_FORM: "/sponsor-een-buddy/buddy-sponsoren-aanmelden",
    TIPS: "/tips",
    STORIES: "/verhalen",
    INFORMATION: "/informatie",
    FORUM_PARENTS: "/forum_ouders",
  },
};

export default URLS;
